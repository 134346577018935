import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Text, Box, Badge } from "../../components/Core";

import styled from "styled-components";

import titleImage from "../../assets/image/jpeg/france.jpg";
import iconQuote from "../../assets/image/png/quote-icon.png";
import styles from '../../assets/styles/blog.module.css';

const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }
  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }
`;

const BadgePost = ({ children }) => (
  <Badge
    mr={3}
    mb={3}
    bg="#eae9f2"
    color="#696871"
    fontSize="16px"
    px={3}
    py={2}
  >
    {children}
  </Badge>
);


const BlogDetails = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="12">
                <Title variant="hero">
                  Get your Australian product needs to be fulfilled while sitting at home in France
                </Title>
                <Box className="d-flex justify-content-center">
                  <Text mr={3}>Feb 14, 2020
                  </Text>
                  <Text>Michael Tippett
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="pb-0 pt-4">
          <Container>
            <Row>
              <Col lg="12" className="mb-5">
                {/* <!-- Blog section --> */}
                <Post>
                  <div>
                    <img src={titleImage} alt="Shopping From France" className={styles.titleImage} />
                  </div>
                  <div>
                    <p>We are a top-quality mail redirection service that have made getting your package from Australia to France more convenient with minimal hold-ups and fast service. All this is done by us sending you a local Australian address which you then give to the Australian merchant for national delivery.</p>
                    <p>You can order from any online and offline store of your choice no matter what the purpose of your purchase is! We deal in both bulk and single orders.</p>
                    <p>Once we receive your product our hardworking employees make sure your product parceling is unharmed and quick.</p>
                    <p>We use direct mail for your shipments making it more cost-effective compared to other available options.</p>
                    <a href="https://members.hotsnail.com.au/signup">Sign up to HotSnail now</a>
                  </div>
                </Post>
                <Box className="d-flex" mt={4}>
                  <BadgePost>France</BadgePost>
                  <BadgePost>Shopping</BadgePost>
                  <BadgePost>Mail forwarding</BadgePost>

                </Box>

              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default BlogDetails;
